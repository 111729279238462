/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Hero,
  Column,
  Columns,
  VariableContent,
  Image,
  List,
  ListItem,
  LeshenPhoneCTA,
  ModalWithState,
  Brandy,
  SplitContent,
  Stack,
  Accordion,
  PackageCard,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Typography } from '@leshen/ui'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const CityTemplate = ({ data }) => {
  const {
    Meta_Description,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
    City,
    Product_Group,
    FAQ_Group,
  } = data.pageInfo.edges[0].node

  const packageBrandyReferences = {
    'Low Speed Copper': ['att-internet18'],
    'High Speed Copper': ['att-internet100'],
    'High Fiber': ['att-internet300', 'att-internet500', 'att-internet1000'],
  }

  const packages =
    packageBrandyReferences[Product_Group]?.map((packageId) =>
      data?.allContentfulPackage?.edges?.find(
        ({ node }) => node?.identifier === packageId
      )
    )?.filter((node) => node) || []

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const FaqQuestions = {
    1: [
      {
        title: `Is there AT&T service in ${City}, ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, AT&T service is offered in {City}, {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does AT&T Internet cost in ${City}, ${State_Name}?`,
        content: (
          <div>
            <Typography>
              The price of AT&T Internet depends on which plan you select. View
              more information on the pricing of AT&T plans in your area by
              looking at the package cards at the top of this page
            </Typography>
          </div>
        ),
      },
    ],
    2: [
      {
        title: `Does ${City}, ${State_Name} offer AT&T Internet services?`,
        content: (
          <div>
            <Typography>
              Yes, {City}, {State_Name} offers AT&T Internet services. For more
              information on the services available, check out the package cards
              at the top of this page or give us a call.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is it to get AT&T service in ${City}, ${State_Name}?`,
        content: (
          <div>
            <Typography>
              The price of your AT&T service depends on what AT&T plan or
              package you select. You can learn what plans and their pricing are
              in {City}, {State_Name} in the package cards at the top of this
              page.
            </Typography>
          </div>
        ),
      },
    ],
  }

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: [
        {
          shortName: 'Home',
          path: '/',
        },
        {
          shortName: 'Availability',
          path: '/availability',
        },
        {
          shortName: State_Name,
          path: `/availability/${State.toLowerCase()}`,
        },
      ],
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }
  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            alignImageToBottom={false}
            mobileImage={
              <Image
                data={data.heroImageMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A content creator wearing headphones smiles at smartphone"
              />
            }
            mainContent={false}
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A content creator wearing headphones smiles at smartphone"
              />
            }
            CustomLink={false}
            backgroundColor={false}
          >
            <Typography variant="h1">
              AT&T Internet in {City}, {State_Name}
            </Typography>
            <List>
              <ListItem>
                <Typography>Fast and dependable speeds</Typography>
                <Brandy
                  text={data.disclaimerLimitedAvailability.text}
                  symbol={data.disclaimerLimitedAvailability.symbol}
                  variant="legal"
                />
              </ListItem>
              <ListItem>
                <Typography>No equipment fees or annual contracts</Typography>
              </ListItem>
              <ListItem>
                <Typography>99% proven reliability</Typography>
                <Brandy
                  text={data.disclaimerNetworkReliability.text}
                  symbol={data.disclaimerNetworkReliability.symbol}
                  variant="legal"
                />
              </ListItem>
            </List>
            <br />
            <br />
            <LeshenPhoneCTA variant="hero" color="primary">
              CALL
            </LeshenPhoneCTA>
          </Hero>

          {packages && packages?.length > 0 && (
            <VariableContent
              mainContent={
                <>
                  <Typography variant="h2">
                    AT&T internet services in {City}, {State_Name}
                  </Typography>
                  <Typography>
                    With AT&T Internet, you get fast, secure service you can
                    depend on.
                  </Typography>
                </>
              }
              alignMainContent="center"
            >
              <Stack spacing="xxl">
                <Columns>
                  {packages?.map((currentPackage) => (
                    <PackageCard
                      label={currentPackage?.node?.label}
                      packageData={{ ...currentPackage?.node?.brandy }}
                      key={currentPackage?.node?.contentful_id}
                      content={
                        <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
                      }
                    />
                  ))}
                </Columns>
              </Stack>
            </VariableContent>
          )}

          <SplitContent
            backgroundColor=""
            alignImageToBottom={false}
            image={
              <Image
                data={data.paidToSwitchImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="Visa Reward Card"
              />
            }
            mobileImage={
              <Image
                data={
                  data.paidToSwitchImageMobile.cloudinary[0].gatsbyImageData
                }
                isImageCritical
                alt="Visa Reward Card"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">Get paid to switch</Typography>
                <Typography variant="h5">
                  Get up to $300 AT&T Visa® Reward Cards when you sign up for
                  AT&T Fiber.
                </Typography>
                <Brandy
                  symbol={data.paidToSwitch1.symbol}
                  text={data.paidToSwitch1.text}
                  variant="legal"
                />
                <Typography variant="h5">
                  Plus, we’ll cover your cancellation fee in full.
                </Typography>
                <Typography variant="legal">
                  <ModalWithState modalText="See offer details">
                    <Brandy
                      symbol={data.disclaimerRewardcardmodal.symbol}
                      text={data.disclaimerRewardcardmodal.text}
                      variant="legal"
                    />
                  </ModalWithState>
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <SplitContent
            backgroundColor="light"
            alignImageToBottom={false}
            image={
              <Image
                data={data.whatYouCanDoImage.cloudinary[0].gatsbyImageData}
                alt="Teenagers play on VR headsets"
              />
            }
            mobileImage={
              <Image
                data={
                  data.whatYouCanDoImageMobile.cloudinary[0].gatsbyImageData
                }
                alt="Teenagers play on VR headsets"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  What you can do with AT&T Fiber® in {City}, {State_Name}
                </Typography>
                <Typography variant="p">
                  Backed by a strong network, AT&T Internet provides speeds up
                  to 5 GIGs† so you can seamlessly stream, work, and play.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      Power multiple devices at the same time
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Experience less buffering</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      No annual contract, equipment fees, or data caps
                    </Typography>
                  </ListItem>
                </List>
                <Brandy
                  symbol={data.speedClaimMultiGig.symbol}
                  text={data.speedClaimMultiGig.text}
                  variant="legal"
                />
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom={false}
            image={
              <Image
                data={data.newServiceImage.cloudinary[0].gatsbyImageData}
                alt="A couple opens moving boxes"
              />
            }
            mobileImage={
              <Image
                data={data.newServiceImageMobile.cloudinary[0].gatsbyImageData}
                alt="A couple opens moving boxes"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Need new service for your new place in {City}, {State_Name}?
                </Typography>
                <Typography variant="p">
                  See what’s available at your new address—plus choose
                  installation options and a time that works best with your busy
                  schedule.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Do I qualify for the Affordable Connectivity Program in {City}
                  ?
                </Typography>
                <Typography variant="p">
                  You might qualify for the federal ACP benefit if you meet one
                  of the following criteria:
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor="primary"
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                backgroundColor="white"
                borderColor="none"
                borderType="none"
                image={false}
                svg={false}
              >
                <Typography variant="h4">
                  Your household income is at or below 200% of federal poverty
                  guidelines.
                </Typography>
                <Typography variant="">
                  Check out the chart on the official federal{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://www.affordableconnectivity.gov/do-i-qualify/"
                  >
                    ACP website
                  </a>{' '}
                  to see if your household qualifies.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                borderColor="none"
                borderType="none"
                image={false}
                svg={false}
              >
                <Typography variant="h4">
                  You participate in a qualifying federal assistance program.
                </Typography>
                <Typography variant="">
                  If you participate in government benefit programs such as
                  SNAP, Medicaid, WIC, you might be eligible for ACP. Take a
                  look at the{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://www.affordableconnectivity.gov/do-i-qualify/"
                  >
                    full list
                  </a>{' '}
                  of qualifying programs to see if you meet the requirements.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <SplitContent
            backgroundColor=""
            alignImageToBottom={false}
            image={
              <Image
                data={data.cityDropdownImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A woman uses a tablet"
              />
            }
            mobileImage={
              <Image
                data={
                  data.cityDropdownImageMobile.cloudinary[0].gatsbyImageData
                }
                isImageCritical
                alt="A woman uses a tablet"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  AT&T service availability near {City}
                </Typography>
                <Typography variant="p">
                  AT&T provides super-fast, super-reliable internet services
                  across the country—see if AT&T is available in your area.
                </Typography>
                <Typography variant="p">
                  AT&T services are available in:
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Explore AT&T internet resources
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor="light"
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                image={
                  <Image
                    data={data.resourcesImage1.cloudinary[0].gatsbyImageData}
                    isImageCritical
                    alt="A woman uses a tablet in the kitchen"
                  />
                }
              >
                <Typography variant="h4">AT&T Resource Center</Typography>
                <Typography variant="">
                  Broaden your internet know-how by visiting the a{' '}
                  <GatsbyLink to="/resources">AT&T Resource Center</GatsbyLink>.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <Image
                    data={data.resourcesImage2.cloudinary[0].gatsbyImageData}
                    isImageCritical
                    alt="A woman works at desk with a laptop and smartphone"
                  />
                }
              >
                <Typography variant="h4">
                  Your guide to Wireless Hotspots
                </Typography>
                <Typography variant="">
                  With this guide, you’ll learn how to optimize{' '}
                  <GatsbyLink to="/resources/internet/what-are-hotspots/">
                    AT&T hotspots
                  </GatsbyLink>{' '}
                  and protect yourself from hackers.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <Image
                    data={data.resourcesImage3.cloudinary[0].gatsbyImageData}
                    isImageCritical
                    alt="Fiber vs cable internet"
                  />
                }
              >
                <Typography variant="h4">Fiber vs. Cable Internet</Typography>
                <Typography variant="">
                  Learn the difference between{' '}
                  <GatsbyLink to="/resources/internet/fiber-vs-cable-internet/">
                    fiber and cable internet
                  </GatsbyLink>{' '}
                  with this handy guide.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <Image
                    data={data.resourcesImage4.cloudinary[0].gatsbyImageData}
                    isImageCritical
                    alt="A man uses streaming service on tablet"
                  />
                }
              >
                <Typography variant="h4">Streaming 101</Typography>
                <Typography variant="">
                  This{' '}
                  <GatsbyLink to="/resources/internet/speed-guide/what-internet-speed-do-i-need-to-stream/">
                    streaming guide
                  </GatsbyLink>{' '}
                  will help you discover the best internet speed for your
                  streaming habits.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          {FaqQuestions[FAQ_Group]?.length > 0 && (
            <VariableContent
              mainContent={
                <Typography variant="h2">Frequently asked questions</Typography>
              }
              alignMainContent="center"
            >
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion items={FaqQuestions[FAQ_Group]} />
              </Stack>
            </VariableContent>
          )}
        </>
      }
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query CityTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerAttAtsAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
          Zip_Code
          Product_Group
          FAQ_Group
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "c8135650-abc9-547b-87a1-c0b4439613dd" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "9d033195-2170-50be-91d5-d8da20a0144e" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    cities: allDatasetManagerAttAtsAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "5rATqftDlBQPtPCefVyizm" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroImageMobile: contentfulMedia(
      contentful_id: { eq: "1p9pPk6g0q74DHLmGwaLTU" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    paidToSwitchImage: contentfulMedia(
      contentful_id: { eq: "2JUVn73h1Iaav9GTSUICoa" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    paidToSwitchImageMobile: contentfulMedia(
      contentful_id: { eq: "3vPJN7Rhb6gVgw6lf3DsyB" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    whatYouCanDoImage: contentfulMedia(
      contentful_id: { eq: "36QeDPl83dmHqnvwlCm3AS" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    whatYouCanDoImageMobile: contentfulMedia(
      contentful_id: { eq: "17oMNoEQDZEleATbFDOLOs" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    newServiceImage: contentfulMedia(
      contentful_id: { eq: "2W16ZcaaoFK0Iexbqg8A1B" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    newServiceImageMobile: contentfulMedia(
      contentful_id: { eq: "3bqVcHQzb1krjIidedELr" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cityDropdownImage: contentfulMedia(
      contentful_id: { eq: "zxCl6lGU3uSlOFEPx72Op" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cityDropdownImageMobile: contentfulMedia(
      contentful_id: { eq: "6kve9Y0LYvemdoWrhAiaNk" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    resourcesImage1: contentfulMedia(
      contentful_id: { eq: "269bHCdlEHTbCQBaeEa7mA" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 276)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    resourcesImage2: contentfulMedia(
      contentful_id: { eq: "w9JHgrh1pV7t2TuxbB1RV" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 276)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    resourcesImage3: contentfulMedia(
      contentful_id: { eq: "46ifz3mlHUp1t93kL2Vgzt" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 276)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    resourcesImage4: contentfulMedia(
      contentful_id: { eq: "44CeIP7BtbMUVJvdaMJKeD" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 276)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    paidToSwitch1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardproximity" }
    ) {
      id
      text
      symbol
    }
    paidToSwitch2: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardcancelfeeproximity" }
    ) {
      id
      text
      symbol
    }
    speedClaimMultiGig: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-speedclaimmultigig" }
    ) {
      id
      text
      symbol
    }
    disclaimerRewardcardmodal: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardmodal" }
    ) {
      id
      text
      symbol
    }
    disclaimerNetworkReliability: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-attsignalreliability-nosymbol" }
    ) {
      id
      text
      symbol
    }
    disclaimerLimitedAvailability: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-limitedavail-nosymbol" }
    ) {
      id
      text
    }
    disclaimerCopyright: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-att-copyright" }
    ) {
      id
      text
      symbol
    }
  }
`
